<template>
    <div class="qiqb-table w-bgc">
        <div class="" style="font-weight: 600;font-size: 30px;">
            用户档案/张三
        </div>
        <div style="display: flex; justify-content: space-between;align-items: center;">
            <div>
                <div>姓名：张三</div>
                <div>姓名：张三</div>
                <div>姓名：张三</div>
                <div>姓名：张三</div>
                <div>姓名：张三</div>
                <div>姓名：张三</div>

            </div>
            <div style="width: 300px;height: 300px;border: 1rpx solid #000;"></div>
        </div>
    </div>
</template>
<script>
import { listSendToInfo } from "../../../api/index.js"
export default {
    data() {
        return {
            id: this.$route.query.id,
        }
    },
    mounted() {
        this.getInfo()
    },
    computed: {
        onQuery: function () {
            // `this` 指向 vm 实例
            return this.$route.query.id
        }
    },
    methods: {
        async getInfo() { 
            let res=await listSendToInfo({userId:this.id})
        },
    },
}
</script>